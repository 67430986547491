import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Suspense, useEffect, lazy } from "react";
// Pages
const About = lazy(()=>import("./pages/About"));
const Blog = lazy(()=>import("./pages/Blog"));
const BlogDetails = lazy(()=>import("./pages/BlogDetails"));
const CaseStudyDetails = lazy(()=>import( "./pages/CaseStudyDetails"));
const Contact = lazy(()=>import("./pages/Contact"));
const HomeOne = lazy(()=>import("./pages/HomeOne"));
const Portfolio = lazy(()=>import("./pages/Portfolio"));
const TeamDetails = lazy(()=>import("./pages/TeamDetails"));
const ScrollToTop = lazy(()=>import("react-scroll-to-top"));
const Branding = lazy(()=>import("./pages/Services/Branding/Branding"));
const Webdevelopment = lazy(()=>import("./pages/Services/WebDevelop/WebDevelop"));
const AppDevelop = lazy(()=>import('./pages/Services/AppDevelop/AppDevelop'));
const DigitalMarketing = lazy(()=>import('./pages/Services/DigitalMarketing/DigitalMarketing'));
const Printing = lazy(()=>import('./pages/Services/Printing/Printing'));
const Coporate = lazy(()=>import( "./pages/Services/Coporate/Coporate"));
const Service = lazy(()=>import("./pages/Service"));

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <Suspense fallback={<div>...Loading</div>}>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeOne />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blog-details' element={<BlogDetails />} />
        <Route
          exact
          path='/case-study-details'
          element={<CaseStudyDetails />}
        />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/servicedetails' element={<Service/>} />
        <Route exact path='/Portfolio' element={<Portfolio />} />
        <Route exact path='/team-details' element={<TeamDetails />} />
        {/* Branding */}
      <Route exact path='/Branding' element={<Branding />} />
      {/* Web Development */}
      <Route exact path="Webdevelopment" element={<Webdevelopment/>} />
      
      {/* Mobile App */}
      <Route exact path="mobileapp" element={<AppDevelop/>} />
      
      {/* Digital Marketing */}
      <Route exact path="DigitalMarketing" element={<DigitalMarketing/>} />
      
      {/* Printing */}
      <Route exact path="Printing" element={<Printing/>} />
   
      {/* Printing */}
      <Route exact path="Coporate" element={<Coporate/>} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
    </Suspense>
  );
}

export default App;
